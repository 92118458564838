<template>
  <el-form
    :model="addForm"
    :rules="rules"
    ref="addForm"
    label-width="82px"
    label-position="left"
    class="demo-form dialog-form"
  >
    <el-form-item label="角色名称：" prop="name">
      <el-input v-model.trim="addForm.name" placeholder="请输入角色名称"></el-input>
    </el-form-item>
    <el-form-item label="角色编码：" prop="code">
      <el-input v-model.trim="addForm.code" placeholder="请输入角角色编码"></el-input>
    </el-form-item>
    <el-form-item label="菜单权限：" prop="menu">
      <menuSelect :value="menuId" @getValue="getMenuId" />
    </el-form-item>
    <!-- <el-form-item label="状态：" prop="status">
      <el-select v-model="addForm.status" clearable placeholder="请选择状态">
        <el-option label="正常" :value="0"></el-option>
        <el-option label="锁定" :value="1"></el-option>
      </el-select>
    </el-form-item>-->
    <el-form-item label="角色描述：" prop="description">
      <el-input
        type="textarea"
        :autosize="{ minRows: 2 }"
        maxlength="50"
        show-word-limit
        placeholder="请输入内容"
        v-model.trim="addForm.description"
      ></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
import { checkRoleName, checkRoleCode } from '@/common/utils/index'
import { insertRole, updateRole, queryRoleById } from "@/api/lib/api.js";
import menuSelect from "@/components/treeSelect/menuSelect.vue";

export default {
  components: {
    menuSelect
  },
  props: {
    roleId: {
      type: [Number, String]
    },
    menuId: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      addForm: {
        code: "",
        description: "",
        id: 0,
        menu: [],
        name: "",
        status: 0
      },
      rules: {
        name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          { validator: checkRoleName, trigger: "blur" }

        ],
        code: [
          { required: true, message: "请输入角色编码", trigger: "change" },
          { validator: checkRoleCode, trigger: "blur" }

        ],
        menu: [{ type: 'array', required: true, message: "请选择菜单", trigger: "change" }],
        status: [{ required: true, message: "请选择角色状态", trigger: "change" }],
        description: [
          { required: true, message: "请输入角色描述", trigger: "change" }
        ]
      },
      companyList: []
    };
  },
  methods: {
    /**获取企业id */
    getMenuId (v) {
      this.addForm.menu = v;
    },
    // 新增/保存用户
    onSave () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let data = {
            code: this.addForm.code,
            description: this.addForm.description,
            menu: this.addForm.menu,
            name: this.addForm.name,
          };
          if (this.roleId) {
            data.id = this.addForm.id;
            updateRole(data).then(res => {
              if (res.code === 0) {
                this.$message.success("修改成功");
              } else {
                this.$message.error(res.message);
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          } else {
            insertRole(data).then(res => {
              if (res.code === 0) {
                this.$message.success("新增成功");
              } else {
                this.$message.error(res.message);
              }
              this.$emit("onDialogClose", res.code === 0);
            });
          }
        }
      });
    }
  },
  created () {
    if (this.roleId) {
      queryRoleById(this.roleId).then(res => {
        if (res.code === 0) {
          this.addForm = { ...res.data };
        }
      });
    }
  },
  mounted () { }
};
</script>