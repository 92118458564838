<template>
  <el-select
    ref="select_element"
    :value="valueTitle"
    :clearable="clearable"
    @clear="resetForm"
    placeholder="请选择"
    class="MenuSelectForSearch"
  >
    <el-option :value="valueTitle" :label="valueTitle" class="options">
      <el-tree
        @check="getKeys"
        :show-checkbox="multiple"
        id="tree-option"
        ref="selectTree"
        :data="menuTree"
        :props="props"
        :node-key="props.value"
        :default-expanded-keys="defaultExpandedKey"
      ></el-tree>
    </el-option>
  </el-select>
</template>

<script>
/**
 * 菜单选择组件，可多选 多选时父子联动
 */
import { getMenus } from "@/api/lib/api.js";
export default {
  props: {
    /* 配置项 */
    props: {
      type: Object,
      default: () => {
        return {
          value: "id", // ID字段名
          label: "name", // 显示名称
          children: "children" // 子级字段名
        };
      }
    },
    /* 初始值 */
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },

    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    /* 多选 */
    multiple: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    searchable: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
  },


  data () {
    return {
      valueId: null, // 初始值
      valueTitle: "",
      defaultExpandedKey: [],
      menuTree: []
    };
  },

  methods: {
    /** 获取公司树形结构*/
    getMenuTreeNode () {
      getMenus({}).then(res => {
        this.menuTree = res.data;
        /**默认展开所有 */
        this.setDefaultExpandedKey(res.data); // 设置默认选中
        this.initScroll();
        this.initHandle(this.value);

      });
    },
    /**默认展开所有 */
    setDefaultExpandedKey (res) {
      for (let i = 0; i < res.length; i++) {
        const element = res[i];
        this.defaultExpandedKey.push(element[this.props.value]); // 设置默认展开
        if (element.children) {
          element.children.map(item => {
            this.defaultExpandedKey.push(item[this.props.value]); // 设置默认展开
          });
        }
      }
    },

    /**多选时checked点击事件*/
    getKeys (data, checked) {
      if (checked.checkedNodes.length !== 0) {
        if (checked.checkedNodes.length == 1) {
          this.valueTitle = checked.checkedNodes[0].name;
        } else {
          let str = "";
          checked.checkedNodes.map(item => {
            str += `${item.name},`;
          });
          this.valueTitle = str;
        }
      } else {
        this.valueTitle = "";
      }
      this.$emit("getValue", checked.checkedKeys);
    },

    /**初始化滚动条*/
    initScroll () {
      this.$nextTick(() => {
        let scrollWrap = document.querySelectorAll(
          ".MenuSelectForSearch .el-scrollbar .el-select-dropdown__wrap"
        )[0];
        let scrollBar = document.querySelectorAll(
          ".MenuSelectForSearch .el-scrollbar .el-scrollbar__bar"
        );
        scrollWrap.style.cssText =
          "margin: 0px; max-height: none; overflow: hidden;";
        scrollBar.forEach(ele => (ele.style.width = 0));
      });
    },

    /**公司可多选与可单选切换时，清空input */
    resetForm () {
      this.valueTitle = "";
      this.valueId = [];
      this.defaultExpandedKey = [];
      this.clearSelected();
      this.$emit("getValue", []);
    },
    /* 清空选中样式 */
    clearSelected () {
      this.$refs.selectTree.setCheckedNodes([]); // 设置默认选中
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach(element => element.classList.remove("is-current"));
    },
    // 初始化值
    initHandle (res) {
      if (res.length > 0) {
        let valueTitle = []
        this.valueId = [...res];
        this.$nextTick(() => {
          this.$refs.selectTree.setCheckedKeys(res); // 设置默认选中
          setTimeout(() => {
            this.$refs.selectTree.getCheckedNodes().forEach(element => {
              valueTitle.push(element.name)
            })
            this.valueTitle = valueTitle.join(","); // 初始化显示
          })
        })
      }
      this.$emit("getValue", this.valueId);
      this.initScroll();
    },
  },

  created () {
    this.getMenuTreeNode();

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
.el-tree >>> .is-current .el-tree-node__label {
  color: #4b8dfd;
  font-weight: 700;
}
.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
  color: #dfdfdf;
  font-weight: normal;
}
</style>
