/* 递归获取菜单第一项 */
let menuRecursion = arr => {
  if (arr[0].subs == null) {
    return arr[0]
  } else {
    return menuRecursion(arr[0].subs)
  }
}

/* 获取hash参数 */
const getQueryString = () => {
  var obg = {},
    a = '';
  (a = window.location.search.substr(1)) ||
  (a = window.location.hash.split('?')[1])
  a.split(/&/g).forEach(function (item) {
    obg[(item = item.split('='))[0]] = item[1]
  })
  return obg
}


export {
  menuRecursion,
  getQueryString
}



// 手机号
const checkPhone = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /(^1[3|4|5|7|8|9]\d{9}$)|(^09\d{8}$)/
    if (!regEn.test(value)) {
      callback(new Error('请输入正确的手机号码'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}


/**
 * @description 判断是否是手机号
 * @param str
 * @returns {boolean}
 */
const isPhone = (str) => {
  const reg = /(^1[3|4|5|7|8|9]\d{9}$)|(^09\d{8}$)/
  return reg.test(str)
}

// 密码
const checkPassword = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9a-zA-Z]{6,18}$/
    if (!regEn.test(value)) {
      callback(new Error('请输入6-18位数字或字母'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 用户名
const checkUsername = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9a-zA-Z]{5,10}$/
    if (!regEn.test(value)) {
      callback(new Error('请输入5-10位数字或字母'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 姓名
const checkName = (rule, value, callback) => {
  if (value && value !== '') {
    // 中文或英文（可混合使用）
    // const regEn = /[a-zA-Z\u4E00-\u9FA5]+$/
    // 中文或英文（不可混合使用）
    let regEn = /^([a-zA-Z ]+|[\u4e00-\u9fa5]+)$/
    if (!regEn.test(value)) {
      callback(new Error('请输入中文或英文,不可混合输入'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 角色名称
const checkRoleName = (rule, value, callback) => {
  if (value && value !== '') {
    let regEn = /^[\u4e00-\u9fa5a-zA-Z0-9]{5,16}$/
    if (!regEn.test(value)) {
      callback(new Error('请输入5到16位中文英文数字'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 角色编码
const checkRoleCode = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[a-zA-Z0-9]{5,16}$/
    if (!regEn.test(value)) {
      callback(new Error('请输入5到16位字符'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 资源路径
const checkSourceURL = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^\/[a-zA-Z]+(\/[0-9a-zA-Z]+)*(\.icon|\.img|\.mp4|\.mp3|\.svg)$/
    if (!regEn.test(value)) {
      callback(new Error('请输入合法的资源路径'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 车牌号
const checkPlateNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/
    if (!regEn.test(value)) {
      callback(new Error('请输入合法的车牌号'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 车架号
const checkFrameNumver = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9a-zA-Z]{17}$/
    if (!regEn.test(value)) {
      callback(new Error('请输入合法的车架号'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 工号
const checkJobNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9a-zA-Z]{1,18}$/
    if (!regEn.test(value)) {
      callback(new Error('工号为不超过18位数字或字母组成'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 从业资格证号
const checkQualificationCertificateNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9A-Z]{1,18}$/
    if (!regEn.test(value)) {
      callback(new Error('从业资格证号为不超过18位数字或大写字母组成'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 驾驶证号
const checkDriverLicenseNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[0-9A-Z]{1,18}$/
    if (!regEn.test(value)) {
      callback(new Error('驾驶证号为不超过18位数字或大写字母组成'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// 身份证号码
const checkIdentificationNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
    if (!regEn.test(value)) {
      callback(new Error('请输入合法的身份证号码'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}
// 邮箱
const checkEmailNumber = (rule, value, callback) => {
  if (value && value !== '') {
    const regEn = /^\w+@[a-z0-9]+\.[a-z]+$/i
    if (!regEn.test(value)) {
      callback(new Error('请输入正确的邮箱地址'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}


export {
  checkPhone,
  isPhone,
  checkPassword,
  checkUsername,
  checkName,
  checkRoleName,
  checkRoleCode,
  checkSourceURL,
  checkPlateNumber,
  checkFrameNumver,
  checkJobNumber,
  checkIdentificationNumber,
  checkQualificationCertificateNumber,
  checkDriverLicenseNumber,
  checkEmailNumber
}

//秒转换成时分秒
const formateSeconds = endTime => {
  let secondTime = parseInt(endTime) //将传入的秒的值转化为Number
  let min = 0 // 初始化分
  let h = 0 // 初始化小时
  let result = ''
  if (secondTime > 60) { //如果秒数大于60，将秒数转换成整数
    min = parseInt(secondTime / 60) //获取分钟，除以60取整数，得到整数分钟
    secondTime = parseInt(secondTime % 60) //获取秒数，秒数取佘，得到整数秒数
    if (min > 60) { //如果分钟大于60，将分钟转换成小时
      h = parseInt(min / 60) //获取小时，获取分钟除以60，得到整数小时
      min = parseInt(min % 60) //获取小时后取佘的分，获取分钟除以60取佘的分
    }
  }
  result = `${h.toString().padStart(2,'0')}:${min.toString().padStart(2,'0')}:${secondTime.toString().padStart(2,'0')}`
  return result
}

export {
  formateSeconds
}